.title-sec {
  font-size: 42px;
  position: relative;
  font-weight: 'bolder';
  margin-bottom: 50px;
  font-family: worksan-semibold;
  text-align: center;
  text-transform: capitalize;
  &:after {
      content: ' ';
      width: 52px;
      height: 2px;
      background: $color-primary;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
  }
  @media screen and (max-width: 991px) {
      font-size: 22px;
  }
}