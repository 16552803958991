#orderdetail {
  .orderdetail-section {
    padding-top: 50px;
    padding-bottom: 50px;
    .add-description {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
      span.address {
        flex: none;
      }
      ul {
        margin: 0;
        padding: 0;
        padding-left: 10px;
        list-style: none;        
        li {
          margin-right: 5px;
          display: inline-block;
          &::after {
            content: ',';
          }
          &:last-child {
            &::after {
              content: '.'
            }
          }
        }
      }
    }
    .table {
      margin-bottom: 0;
      thead {
        @media screen and (max-width: 991px) {
          display: none;
        }
        tr {
          th {
            text-transform: uppercase;
          }
          th:last-child{
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          td {
            @media screen and (max-width: 991px) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              font-size: 14px;
            }
            strong {
              display: none;
              text-transform: uppercase;
              @media screen and (max-width: 991px) {
                display: block;
                width: 50%;
              }
            }
            span {
              @media screen and (max-width: 991px) {
                padding-left: 0.75rem;
              }
            }
            p:first-child {
              font-weight: 600;
            }                               
          }
          td:last-child{
            text-align: right;
            @media screen and (max-width: 991px) {
              text-align: left;
            }
          }
        }
        .product-row {
          td {
            .product, .material, .color {
              @media screen and (max-width: 991px) {
                padding-left: 0.75rem;
              }
              figure {
                margin-bottom: 10px;
                padding: 5px;
                &.product-figure {
                  background: rgba($color-gray, 0.05);
                  width: 115px;
                  @media screen and (max-width: 991px) {
                    width: 100%;
                  }
                }
                &.material-figure {
                  border: 1px solid rgba($color-gray, 0.05);
                  width: 60%;
                  @media screen and (max-width: 991px) {
                    width: 100%;
                  }
                  .img-wrap {
                    @media screen and (max-width: 991px) {
                      width: 73px;
                    }
                  }
                }
                &.color-figure {
                  width: 50%;
                  margin-bottom: 0;
                  @media screen and (max-width: 991px) {
                    width: 50%;
                  }
                }
                .img-wrap {
                  img {
                    width: 100%;
                    opacity: 0;
                  }
                }
              }
              p {
                margin-bottom: 0;
                font-size: 16px;
                color: $color-gray;
                font-family: roboto-bold;
                @media screen and (max-width: 767px) {
                  font-size: 12px;
                }
              }
            }
            .color {
              display: block;
            }
          }
        }
      }  
      .totalprice {
        @media screen and (max-width: 991px) {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        }
        td {
          border: none;
        }
        .blank {
          width:50%;
          @media screen and (max-width: 991px) {
            display: none;
          }
        }
        .price {
          width: 25%;
          text-align: right;
          font-family: roboto-bold;
          @media screen and (max-width: 991px) {
            width: 50%;
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }
    }
    .btn-history {
      margin-top: 30px;
      .btn-goto {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 11px;
        padding-bottom: 11px;
        &:hover {
          text-decoration: none;
        }
      }        
    }
  }
}