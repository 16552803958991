.product-detail {
  margin-top: 50px;
  .prod-img {
    width: 100%;
    display: block;
    background: $color-light;
    border: 1px solid $color-gray-2;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .prod-desc {
    .prod-name {
      font-size: 32px;
      color: $color-text;
      margin-bottom: 21px;
    }
    .prod-content {
      font-size: 15px;
      color: $color-gray;
      margin-bottom: 20px;
    }
    .prod-remark {
      font-size: 17px;
      list-style: none;
      padding-left: 0;
      color: $color-warning;
      margin-bottom: 0px;
      strong {
        font-family: roboto-medium;
      }
      li {
        margin-bottom: 10px;
      }
    }
  }
  .order-guide {
    margin-top: 30px;
    h4 {
      font-size: 20px;
      font-family: roboto-bold;
      margin-bottom: 45px;
      @media screen and (max-width: 1199px) {
        margin-bottom: 60px;
      }
      @media screen and (max-width: 991px) {
        margin-bottom: 45px;
      }
    }
    .order-guide-list {
      list-style: none;
      padding-left: 0;
      display: flex;
      margin-bottom: 50px;
      li {
        width: 130px;
        height: 130px;
        padding: 10px;
        margin-right: 30px;
        border: 1px solid $color-gray-2;
        position: relative;
        @media screen and (max-width: 575px) {
          width: 50px;
          height: 50px;
          margin-right: 23px;
        }
        &:not(:last-child):after {
          content: " ";
          border: 10px solid transparent;
          border-left-color: $color-gray-2;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -30px;
          @media screen and (max-width: 575px) {
            border: 6px solid transparent;
            right: -23px;
            border-left-color: $color-gray-2;
          }
        }
        figure {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          transform: translate3d(-50%, -50%, 0);
          padding: 10px;
          margin-bottom: 0;
          @media screen and (max-width: 575px) {
            padding: 5px;
          }
          img {
            width: 100%;
          }
        }
        p {
          margin: 0;
          position: absolute;
          top: -25px;
          left: 0;
          font-size: 12px;
          font-family: roboto-bold;
          @media screen and (max-width: 1199px) {
            top: -35px;
            font-size: 10px;
          }
        }
        // @media screen and (max-width: 768px) {
        //   width: 60px;
        //   height: 60px;
        //   figure figcaption {
        //     top: 0;
        //     transform: translateY(-100%);
        //   }
        // }
      }
    }
  }
  .product-section {
    border-bottom: 1px solid $color-gray-2;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
  .order-info {
    list-style: none;
    padding-left: 0;
    margin-bottom: 50px;
    margin-top: 20px;
    li {
      margin-bottom: 20px;
      strong {
        padding-right: 15px;
        min-width: 90px;
        display: inline-block;
      }
    }
    .color-wrap, .spanner-wrap {
      display: flex;
      align-items: center;
    }
    .spanner-wrap {
      margin-top: 20px;
    }
    .color-wrap {
      margin-bottom: 0;
      strong {
        margin-bottom: 15px;        
      }
    }
    .error {
      margin-top: 0;
      margin-bottom: 20px;
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
    .item-color {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      li {
        width: 50px;
        height: 50px;
        padding: 5px;
        border: 1px solid $color-gray-2;
        margin-right: 15px;
        cursor: pointer;
        margin-bottom: 15px;
        outline: 0px solid transparent;
        &:hover {
          outline: 2px solid $color-primary;
        }
        &.active {
          outline: 2px solid $color-primary;
        }
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
    .qty-spanner {
      width: 150px;
      padding: 10px;
      background: $color-light;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span:not(:nth-child(2)) {
        width: 30px;
        height: 20px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
