#pre-order {
  .order-progress ul {
    width: 100%;
    border-bottom: 1px solid $color-gray-2;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding-left: 0;
    list-style: none;
    margin-bottom: 100px;
    li {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      transform: translateY(calc(100% - 5px));
      font-size: 15px;
      @media screen and (max-width: 991px) {
        max-width: 100px;
      }
      &::before {
        content: " ";
        display: inline-block;
        width: 10px;
        height: 10px;
        background: $color-light;
        border: 1px solid $color-gray-2;
        border-radius: 5px;
        transition: all 0.3s ease;
        margin-bottom: 15px;
      }
      &.active {
        &::before {
          background: $color-warning !important;
          border: 1px solid $color-warning;
          box-shadow: 0px 0px 0px 2px rgba($color-warning, 0.5);
        }
      }
      &.done {
        &::before {
          background: $color-primary;
          box-shadow: 0px 0px 0px 2px rgba($color-primary, 1);
          border-color: $color-primary;
        }
        &::after {
          content: " ";
          display: inline-block;
          width: 8px;
          height: 5px;
          border: 1px solid transparent;
          border-bottom-color: $color-light;
          border-left-color: $color-light;
          position: absolute;
          top: 1px;
          transform: rotate(-45deg);
        }
      }
      // &:hover {
      //   &::before {
      //     background: rgba($color-warning, 0.7);
      //   }
      // }
    }
  }
  .prod-name {
    font-size: 32px;
  }
  .step-title {
    font-size: 24px;
    font-family: roboto-medium;
    margin-bottom: 25px;
    .info {
      font-size: 15px;
      color: $color-warning;
      display: block;
      font-family: roboto-light;
      margin-top: 10px;
    }
  }
  .order-material {
    width: 100%;
    padding-left: 0;
    list-style: none;
    li {
      padding: 15px;
      padding-left: 0;
      border-bottom: 1px solid $color-gray-2;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      &.active {
        background: rgba($color-gray-2, 0.2);
        padding: 15px;
        border-bottom: 0;
        box-shadow: 1px 1px 10px rgba($color-gray-2, 0.7);
      }
      &:last-child {
        border-bottom: 0;
      }
      figure {
        width: 150px;
        height: 150px;
        border: 1px solid $color-gray-2;
        padding: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .mat-desc {
        width: calc(100% - 165px);
        * {
          margin-bottom: 0;
        }
        h4,
        .mat-price {
          font-size: 17px;
          font-family: roboto-medium;
          color: $color-text;
          margin-bottom: 15px;
        }
        .mat-info {
          font-size: 15px;
          font-family: roboto-light;
          color: $color-text-2;
          margin-bottom: 10px;
        }
        button {
          width: 150px;
          text-transform: uppercase;
          background: none;
          border: 1px solid $color-dark;
          height: 40px;
          color: $color-text;
          padding: 0;
          @media screen and (max-width: 575px) {
            width: 100%;
            min-width: auto;
          }
        }
      }
    }
  }
  .order-color {
    padding-left: 0;
    list-style: none;
    // margin-bottom: 50px;
    > li {
      position: relative;
      width: 160px;
      &::before {
        opacity: 0;
        visibility: hidden;
      }
      &:hover {
        .colors-wrap {
          visibility: visible;
          opacity: 1;
        }
        &::before {
          content: " ";
          visibility: visible;
          opacity: 1;
          display: inline-block;
          // border: 10px solid transparent;
          // border-right-color: rgba($color-gray-2, 0.5);
          position: absolute;
          left: 85%;
          top: 50%;
          transform: rotate(45deg) translateY(-50%);
          width: 25px;
          height: 25px;
          background: $color-secondary;
          box-shadow: 1px 1px 10px rgba($color-gray-2, 0.9);
          @media screen and (max-width: 1023px) {
            top: 113%;
            left: calc(50% - 30px);
            // border: none;
            // border-right-color: transparent;
            // border-bottom-color: $color-gray-2;
            // transform: none;
          }
        }
      }
    }
    strong {
      display: block;
      margin-bottom: 10px;
      &:after {
        content: '*';
        color: $color-danger;
        margin-left: 5px;
      }
    }
    figure {
      width: 140px;
      height: 80px;
      padding: 80px;
      padding: 5px;
      border: 1px solid $color-gray-2;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .colors-wrap {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      position: absolute;
      left: 140px;
      top: 50%;
      padding: 0;
      padding-left: 15px;
      transform: translateY(-50%);
      @media screen and (max-width: 1023px) {
        top: calc(100% + 0px);
        left: 0;
        z-index: 1;
        padding-left: 0;
        padding-top: 15px;
        transform: none;
      }
      @media screen and (max-width: 575px) {
        width: 290px;
      }
    }
    .colors {
      padding-left: 0;
      list-style: none;
      // width: 320px;
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      box-shadow: 1px 1px 10px rgba($color-gray-2, 0.9);
      background: $color-secondary;
      flex-wrap: wrap;
      width: 335px;
      min-height: 117px;
      // opacity: 0;
      // visibility: hidden;
      // transition: all 0.3s ease;
      // position: absolute;
      // left: 160px;
      // top: 22px;
      @media screen and (max-width: 1023px) {
        // top: calc(100% + 20px);
        // left: 0;
        // z-index: 1;
        background: $color-secondary;
      }
      li {
        width: 46px;
        height: 36px;
        border-radius: 5px;
        margin-top: 15px;
        overflow: hidden;
        margin-right: 15px;
        cursor: pointer;
        @media screen and (max-width: 575px) {
          width: 40px;
          height: 40px;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          margin-top: 0;
        }
        &:nth-child(5n + 5) {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 100%;
        }
        &.active {
          border: 2px solid $color-primary;
        }
      }
    }
  }
  .color-error {
    margin-bottom: 15px;
  }
  .product-section {
    border-bottom: 0;
    margin-bottom: 0;
  }
  .button-step {
    button {
      background: $color-gray;
    }
  }
  .choosed-color {
    list-style: none;
    padding-left: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    li {
      margin-right: 15px;
      cursor: pointer;
      width: 30%;
      margin-bottom: 40px;
      @media screen and (max-width: 991px) {
        width: auto;
      }
    }
    strong {
      font-family: roboto-bold;
      margin-bottom: 15px;
      display: block;
    }
    figure {
      width: 160px;
      height: 100px;
      text-align: center;
      padding: 5px;
      display: block;
      border: 1px solid $color-gray-2;
      @media screen and (max-width: 575px) {
        width: 136px;
        height: 90px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      figcaption {
        margin-top: 10px;
      }
    }
  }
  .message {
    width: 100%;
    min-height: 150px;
    border: 1px solid $color-gray-2;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 45px;
  }
  .price-table {
    width: 100%;
    margin-bottom: 50px;
    @media screen and (max-width: 575px) {
      font-size: 14px;
    }
    th {
      padding-bottom: 5px;
      @media screen and (max-width: 575px) {
        vertical-align: text-top;
      }
    }
    th.cost, td.cost {
      min-width: 80px;
      @media screen and (max-width: 575px) {
        display: none;
      }
    }
    input {
      width: 80px;
      text-align: center;
      @media screen and (max-width: 575px) {
        width: 60px;
      }
    }
    .price {
      font-family: roboto-bold;
      @media screen and (max-width: 575px) {
        display: none;
      }
    }
  }
  .summary {
    list-style: none;
    padding-left: 0;
    font-family: roboto-bold;
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .info {
    margin-bottom: 30px;
    p {
      margin-bottom: 0;
      font-size: 15px;
      color: $color-text-2;
      word-break: break-all;
    }
    .warning {
      color: $color-warning;
      margin-bottom: 5px;
    }
  }
  .info-ship {
    h3 {
      font-family: roboto-medium;
      font-size: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      border-bottom: 1px solid $color-gray-2;
      button {
        margin-bottom: 0;
      }
    }
  }
  .table-order {
    width: 100%;
    border: 1px solid $color-gray-2;
    font-size: 15px;
    margin-bottom: 15px;
    th,
    td {
      border-bottom: 1px solid $color-gray-2;
      text-align: center;
      padding: 5px;
      @media screen and (max-width: 720px) {
        font-size: 12px;
      }
    }
    td {
      font-family: roboto-light;
      font-size: 14px;
      @media screen and (max-width: 720px) {
        font-size: 12px;
      }
      strong {
        font-family: roboto-medium;
      }
    }
    tfoot {
      td {
        border-bottom: 0;
      }
    }
    .mat-img {
      display: inline-block;
      width: 80px;
      padding: 4px;
      background: rgba($color-gray-2, 0.2);
      border: 1px solid $color-gray-2;
      margin-bottom: 0;
      @media screen and (max-width: 720px) {
        width: 60px;
        padding: 2px;
      }
      img {
        margin-bottom: 0;
        width: 100%;
      }
    }
    .mat-colors {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        padding: 3px solid $color-gray-2;
        margin-bottom: 0;
        .mat-img {
          width: 40px;
          @media screen and (max-width: 720px) {
            width: 30px;
          }
        }
      }
    }
  }
  .info-address {
    background: $color-gray;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    * {
      color: $color-secondary;
    }
    strong {
      width: 160px;
    }
    .txt {
      width: calc(100% - 160px);
      font-family: roboto-light;
      margin-bottom: 0;
      @media screen and (max-width: 720px) {
        width: 100%;
      }
    }
  }
  #shipping {
    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 24px;
      font-family: roboto-medium;
      border-bottom: 1px solid $color-gray-2;
      margin-bottom: 50px;
      padding-bottom: 15px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
      span {
        font-size: 15px;
        color: $color-text-2;
        button {
          margin-left: 15px;
          margin-bottom: 0;
          @media screen and (max-width: 767px) {
            min-width: 100px;
          }
        }
      }
    }
    .button-step {
      margin-top: 30px;
    }
  }
  .cart-item {
    padding: 10px;
    border: 1px solid $color-gray-2;
    margin-bottom: 20px;
    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
    @media screen and (max-width: 376px) {
      padding: 0;
    }
    .cart-item-row {
      @media screen and (max-width: 991px) {
        display: grid;
      }
    }
    table {
      width: 100%;
      thead {
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      th,
      td {
        border-bottom: 1px solid $color-gray-2;
        text-align: center;
        padding: 8px 5px;
        font-family: roboto-light;
        &:last-child {
          text-align: right;
        }
        @media screen and (max-width: 991px) {
          display: flex;
          align-items: flex-start;
        }
        strong {
          @media screen and (min-width: 992px) {
            display: none;
          }
          @media screen and (max-width: 991px) {
            width: 50%;
            text-align: left;
          }
        }
      }
      [colSpan="6"] {
        text-align: left;
      }
      .gender {
        text-transform: capitalize;
      }
      .product-img {
        margin-bottom: 0;
        
        img {
          border: 1px solid $color-gray-2;
          margin-bottom: 5px;
          max-width: 100px;
        }
        figcaption {
          font-size: 16px;
          font-family: roboto-bold;
        }
        @media screen and (max-width: 991px) {
          img {
            max-width: 70px;
          }
          figcaption {
            font-family: roboto-light;
            @media screen and (max-width: 991px) {
              font-size: 12px;
            }
          }
        }
      }
      .material-img {
        .material {
          max-width: 100px;
          @media screen and (max-width: 991px) {
            max-width: 40px;
          }
        }
        figcaption {
          font-size: 14px;
        }
      }
      .colors {
        padding-left: 0;
        list-style: none;
        li {
          margin-bottom: 5px;
          margin-bottom: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
          }
          img {
            max-width: 50px;
            border: 1px solid #DDD;
          }
          span {
            font-size: 14px;
          }
        }
      }
      tbody {
        td:last-child {
          text-align: right;
        }
      }
      tfoot {
        tr {
          @media screen and (max-width: 991px) {
            display: flex;
            align-items: flex-start;
          }
        }
        td {
          width: 50%;
          text-align: right;
          border: none;
          font-family: roboto-medium;
          &:first-child {
            @media screen and (max-width: 991px) {
              font-weight: bold;
            }
          }
          &:last-child {
            @media screen and (max-width: 991px) {
              padding-left: 0; 
            }
          }
        }
      }
    }
  }
  .cart-desc {
    background: $color-gray;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
    .deli-title {
      color: $color-secondary;
      font-family: roboto-regular;
      max-width: 160px;
      flex: none;
      margin-right: 10px;
      @media screen and (max-width: 991px) {
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
    p {
      margin-bottom: 0;
      color: $color-secondary;
    }
  }
  .payment {
    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
      input {
        cursor: pointer;
      }
      label {
        margin-left: 5px;
        cursor: pointer;
        margin-bottom: 0;
      }
    }
    .frame {
      border: 1px solid $color-gray-2;
      padding: 15px;
      position: relative;
      color: $color-text-2;
      margin-bottom: 45px;
      .address-edit {
        font-size: 12px;
        position: absolute;
        top: 2px;
        right: 5px;
        color: rgba($color-warning, 0.5);
        cursor: pointer;
        img {
          width: 18px;
        }
      }
      .address-edit-list {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          display: inline-block;
          &:after {
            content: ',';
            margin-right: 5px;
          }
          &:last-child {
            &::after {
              content: '.';
            }
          }
        }
      }
      .frame-label {
        position: absolute;
        transform: translateY(-120%);
        left: 0;
        top: 0;
        font-size: 17px;
        font-family: roboto-bold;
      }
    }
  }
}
