#signin, #signup, #resetpass, #verification, #confirmpass {
  .account-wrap {
    padding-top: 90px;
    padding-bottom: 90px;
    .account-form {
      margin: 0 auto;
      max-width: 550px;
      width: 100%;
      padding: 40px;
      border: 1px solid rgba($color-gray, .1);
      @media screen and (max-width: 991px) {
        padding: 20px;
      }
      form {
        margin-top: 30px;
      }
      .form-group {
        &:last-child {
          margin-bottom: 5px;
        }
      }
      h2 {
        font-family: worksan-bold;
        margin-bottom: 20px;
        text-align: center;
        font-size: 24px;
      }
      .desc {
        margin-bottom: 0;
        text-align: center;
        color: rgba($color-gray, .8)
      }
      button {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 25px;
      }
      .btn-link {
        text-align: right;
        padding: 0;
        margin: 0;
        text-transform: none;
        font-size: 14px;
        text-decoration: none;
        color: $color-primary;
        &:hover {
          color: rgba($color-primary, .8);
        }
      }
      .forgot {
        text-align: right;
        margin-bottom: 0;
        a {
          color: $color-primary;
          &:hover {
            color: rgba($color-primary, .7);
          }
        }
      }
      .resend {
        text-align: right;
        margin-bottom: 0;
        span {
          cursor: pointer;
          color: $color-primary;
          text-decoration: underline;
          &:hover {
            color: rgba($color-primary, .7);
          }
        }
      }
      .donthave {
        margin-bottom: 0;
        text-align: center;
        a {
          color: $color-primary;
          &:hover {
            color: rgba($color-primary, .7);
          }
        }
      }
    }
  }
}