.component-tab {
  .tab-listing {
    list-style: none;
    padding-left: 0px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    li {
      padding: 10px 35px;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      background: rgba($color-gray, .05);
      border-radius: 0;
      font-family: roboto-bold;
      text-transform: uppercase;
      font-size: 14px;
      &.active {
        background: $color-primary;
        color: $color-secondary;
        cursor: auto;
        &:hover {
          color: $color-secondary;
        }
      }
      &:hover {
        color: $color-primary;
      }
      &:first-child{
        margin-left: 0px;
      }
      a {
        text-decoration: none;
      }
    }
    
  }
}