#editprofile {
  .account-wrap {
    padding-top: 60px;
    padding-bottom: 60px;
    @media screen and (max-width: 991px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .account-form {
      margin: 0 auto;
      max-width: 560px;
      width: 100%;
      padding: 0px;
      border: 1px solid transparent;
      .form-group {
        &:last-child {
          margin-bottom: 5px;
        }
      }
      .btn-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button, a {
          min-width: 160px;
          margin-bottom: 10px;
          margin-top: 25px;
        }
      }
    }
  }
}