.custom-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup-content {
    width: 360px;
    border-radius: 0;
    padding: 30px;
    position: relative;
    z-index: 1;
    transform: translateY(-100px);
    background: $color-secondary;
    text-align: center;
    @media screen and (max-width: 575px) {
      width: 100%;
      margin-right: 15px;
      margin-left: 15px;
    }
    h2 {
      position: relative;
      font-size: 18px;
      margin: 40px 0;
      font-family: roboto-regular;
      line-height: 1.5;
      @media screen and (max-width: 575px) {
        font-size: 16px;
      }
      &::before {
        content: ' ';
        display: inline-block;
        width: 100px;
        height: 1px;
        position: absolute;
        bottom: -15px;
        left: calc(50% - 50px);
      }
    }
    button, a {
      width: 100%;
      display: block;
    }
  }
  .custom-popup-close {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color-dark, .5);
  }
  .correct {
    width: 65px;
    height: 65px;
  }
}