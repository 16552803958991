$color-primary: #3bd100;
$color-secondary: #ffffff;
$color-gray: #323232;
$color-gray-2: #dddddd;
$color-dark: #000000;
$color-danger: #d80027;
$color-warning: #ff3d3d;
$color-yellow: #ffbb00;
$color-light: #f5f5f5;
$color-text: #333333;
$color-text-2: #616161;
