.cusbtn {
  font-size: 14px;
  border: none;
  padding: 8px 10px;
  outline: none;
  transition: all 0.3s ease;
  background: $color-gray;
  color: $color-secondary;
  border-radius: 0;
  min-width: 120px;
  text-transform: uppercase;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 2px solid transparent;
  vertical-align: baseline;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
  &:hover {
    box-shadow: 0px 3px 6px rgba($color-dark, 0.5);
    color: $color-secondary;
  }
  .icn-lft {
    width: 12px;
    transform: translate3d(-5px, -1px, 0);
  }
  .icn-rht {
    width: 12px;
    transform: translate3d(5px, -1px, 0);
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-disabled {
  opacity: .5;
  cursor: auto !important;
  box-shadow: none;
  &:hover {
    box-shadow: none;
    &:before {
      transform: rotate(45deg) translate3D(-4px, 2px, 0) !important;
    }
    &:after {
      transform: rotate(-135deg) translate3D(-2px, 4px, 0) !important;
    }
  }
}
.btn-transparent {
  background: transparent;
  border: 2px solid $color-gray;
  color: $color-gray;
  &:hover {
    color: $color-gray;
  }
}

.btn-yellow {
  background: $color-yellow;
}

.btn-link {
  background: transparent;
  border: 2px solid transparent;
  color: $color-gray;
  text-transform: capitalize;
  font-family: roboto-bold;
  text-decoration: underline;
  &:hover {
    color: rgba($color-gray, 0.7);
    box-shadow: none;
  }
}

.button-step {
  display: flex;
  margin-bottom: 50px;
  &.left {
    button {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.right {
    justify-content: flex-end;
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
    button {
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &.center {
    justify-content: center;
    button {
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &.between {
    justify-content: space-between;
  }
  &.lg {
    button {
      min-width: 170px;
      @media screen and (max-width: 575px) {
        min-width: 120px;
      }
    }
  }
  button {
    height: 42px;
    min-width: 120px;
    border: none;
    background: $color-gray;
    text-transform: uppercase;
    color: $color-light;
    font-size: 15px;
    &:focus {
      outline: none;
    }
    &::before,
    &:after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border: 2px solid $color-light;
      border-top-color: transparent;
      border-right-color: transparent;
      transition: all 0.3s ease;
    }
    &:first-child {
      &::after {
        display: none;
      }
      &::before {
        transform: rotate(45deg) translate3D(-4px, 2px, 0);
      }
      &:hover {
        &::before {
          transform: rotate(45deg) translate3d(-7px, 5px, 0);
        }
      }
    }
    &:last-child {
      background: $color-yellow;
      &::before {
        display: none;
      }
      &::after {
        transform: rotate(-135deg) translate3D(-2px, 4px, 0);
      }
      &:hover {
        &::after {
          transform: rotate(-135deg) translate3D(-5px, 7px, 0);
        }
      }
    }
  }
}
