.pc-secnav {
  background: $color-secondary;
  border-bottom: 3px solid $color-primary;
  > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ph-wrap {
    padding-top: 5px;
    padding-bottom: 5px;
    a {
      font-family: roboto-bold;
      font-size: 14px;
    }
    img {
      width: 18px;
      margin-right: 5px;
    }
  }
  .pc-option {
    position: relative;
    &:hover {
      ul {
        visibility: visible;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        transform: translateY(0px);
      }
    }
    p,
    ul {
      font-family: roboto-bold;
      font-size: 14px;
      font-weight: 800;
      margin: 0;
      padding: 0;
    }
    > p {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-left: 5px;
        background: url("../../assets/img/down-arrow.svg") no-repeat
          center/cover;
      }
    }
    ul {
      list-style: none;
      box-shadow: 0px 3px 6px rgba($color-dark, 0.2);
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 20px;
      right: 0;
      min-width: 112px;
      background: $color-secondary;
      transform: translateY(20px);
      transition: all 0.3s ease-in-out;
      z-index: 99;
      li {
        p {
          transition: all 0.3s;
          color: $color-gray;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 5px 10px;
          cursor: pointer;
          &:hover {
            text-decoration: none;
            background: rgba($color-gray, 0.2);
          }
          &::before {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
          &.en {
            &::before {
              background: url("../../assets/img/uk.svg") no-repeat center/cover;
            }
          }
          &.my {
            &::before {
              background: url("../../assets/img/myanmar.svg") no-repeat
                center/cover;
            }
          }
        }
      }
    }
  }
}
.pc-prinav {
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 3px 6px rgba($color-dark, 0.1);
  position: relative;
  .pc-prinav-row {
    position: relative;
  }
  .logo {
    margin-right: 20px;
    margin-bottom: 0;
    img {
      width: 155px;
      @media screen and (max-width: 1199px) {
        width: 100px;
      }
    }
  }
  .nav-wrap {
    position: static;
    .nav-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      font-size: 14px;
      height: 100%;
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        font-size: 12px;
      }
      @media screen and (max-width: 991px) {
        display: block;
        position: fixed;
        top: 0;
        left: 100%;
        padding: 0 20px;
        width: 250px;
        height: 100vh;
        border-left: 3px solid $color-primary;
      }
      .nav-item {
        margin-right: 30px;
        @media screen and (max-width: 991px) {
          padding: 20px 0;
          width: 100%;
        }
        a {
          font-family: roboto-medium;
          font-size: 14px;
          text-transform: capitalize;
          &.active {
            color: $color-primary;
          }
        }
      }
      .mega-menu {
        &:hover {
          .mega-menu-wrap {
            visibility: visible;
            opacity: 1;
            transition: all 0.3s ease-in-out;
            transform: translateY(0px);
          }
        }
        .mega-menu-wrap {
          position: absolute;
          left: 15px;
          width: 100%;
          background: transparent;
          z-index: 99;
          transform: translateY(20px);
          transition: all 0.3s ease-in-out;
          visibility: hidden;
          opacity: 0;
          padding-top: 30px;
          &::before {
            content: "";
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
            border-bottom: 15px solid $color-primary;
            position: absolute;
            top: 15px;
            right: 62%;
            transform: translateX(-50%);
          }
          @media screen and (max-width: 991px) {
            display: none;
          }
          @media screen and (min-width: 992px) {
            max-width: 930px;
            top: 18px;
          }
          @media screen and (min-width: 1200px) {
            max-width: 1110px;
            top: 40px;
          }
          .mega-menu-row {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            box-shadow: 0px 0px 10px rgba($color-dark, 0.1);
            background: $color-secondary;
            .mega-menu-column {
              padding: 30px;
              width: 50%;
              h5 {
                margin-bottom: 20px;
                font-size: 1rem;
                text-transform: uppercase;
                color: $color-dark;
              }
              .mega-item-wrap {
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                position: relative;
                .mega-item {
                  margin-bottom: 10px;
                  width: 33.33%;
                  .item-link {
                    display: flex;
                    align-items: baseline;
                    justify-content: flex-start;
                    font-family: roboto-regular;
                    &::before {
                      content: "";
                      display: inline-block;
                      width: 8px;
                      height: 8px;
                      margin-right: 5px;
                      background: url("../../assets/img/rightarrow-green.svg")
                        no-repeat center/cover;
                      flex: none;
                    }
                  }
                }
              }
              .first-mega {
                &::after {
                  content: "";
                  background: rgba($color-gray, 0.15);
                  position: absolute;
                  height: 100%;
                  width: 2px;
                  right: -30px;
                  top: 0px;
                }
              }
            }
          }
        }
      }
    }
    .profile {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      z-index: 9;
      @media screen and (max-width: 991px) {
        right: 60px;
      }
      &:hover {
        .profile-popup {
          visibility: visible;
          opacity: 1;
          transform: translateY(-20px);
        }
      }
      .name {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        span {
          font-family: roboto-medium;
          font-size: 14px;
          color: $color-gray;
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .profile-popup {
      min-width: 130px;
      padding: 15px;
      background: $color-secondary;
      box-shadow: 1px 1px 10px rgba($color-gray, 0.2);
      position: absolute;
      top: 42px;
      right: 0;
      border-radius: 5px;
      transition: all 0.3s ease;
      visibility: hidden;
      opacity: 0;
      transform: translateY(20px);
      .profile-item {
        padding-left: 0;
        list-style: none;
        max-height: 300px;
        margin-bottom: 0;
        li {
          margin-bottom: 8px;
          transition: all 0.2s;
          font-size: 14px;
          display: flex;
          align-items: baseline;
          justify-content: flex-start;
          font-family: roboto-medium;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            font-family: roboto-medium;
            &:hover {
              text-decoration: none;
              color: $color-primary;
            }
          }
          &:hover {
            text-decoration: none;
            color: $color-primary;
          }
        }
      }
      &.active {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  figure {
    margin-bottom: 0;
  }
  .menu-bugger {
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
      position: absolute;
      right: 15px;
      top: calc(50% - 18px);
    }
  }
}

.nav-item {
  @media screen and (max-width: 991px) {
    transform: translateX(50%);
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }
}

.open-bugger {
  .nav-item {
    @media screen and (max-width: 991px) {
      transform: translateY(0%) !important;
      opacity: 1 !important;
      transition: all 0.4s ease-in-out;
    }
  }
}
