.breadcrumb {
  margin-bottom: 0;
  padding: 30px 0;
  background: rgba($color-gray, .05);
  @media screen and (max-width: 991px) {
    padding: 20px 0;
  }
  .breadcrumb-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      margin-bottom: 0;
      font-size: 24px;
      font-family: worksan-medium;
      @media screen and (max-width: 991px) {
        font-size: 16px;
      }
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    ul {
      padding-left: 0;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin-bottom: 0;
      @media screen and (max-width: 991px) {
        display: none;
      }
      li {
        text-transform: capitalize;
        list-style: none;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:not(:last-child):after {        
          content: '';
          margin: 0px 15px 0px 15px;
          width: 7px;
          height: 7px;
          display: block;
          border: 2px solid #616161a1;
          transform: rotate(45deg);
          border-bottom: 0px;
          border-left: 0px;
        }
        span{
          text-overflow: ellipsis;
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;  
        }
      }
    }
  }
}