.table {
  border: 1px solid rgba($color-gray, .1);
  th, td {
    border-top: 1px solid rgba($color-gray, .1);
  }
  thead {
    th {
      border-top: 1px solid rgba($color-gray, .1);
      font-size: 16px;
      font-family: roboto-medium;
      border-bottom: 0 none;
      font-weight: normal;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
  td {
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  tbody {
    font-size: 15px;
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}