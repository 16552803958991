#terms, #policy {
  .terms-wrap {
    padding-top: 30px;
    padding-bottom: 30px;
    .sidebar {
      padding: 20px;
      background: rgba($color-gray, .05);
      margin-bottom: 30px;
      a {
        margin-bottom: 30px;
        display: block;
        font-family: worksan-medium;
        &.active {
          color: $color-primary;
          cursor: auto;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .info-wrap {
      margin-bottom: 20px;
      h4 {
        margin-bottom: 15px;
      }
    }
  }
}