#profile {
  .section-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
    p {
      margin-bottom: 0;
      font-family: roboto-light;
    }
    .section-profile {
      max-width: 750px;
      padding: 35px;
      border-radius: 0;
      background: rgba($color-gray, .05);
      margin-bottom: 30px;
      ul.profile-list {
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        list-style: none;
      }
      li {
        width: 50%;
        margin-bottom: 30px;
        @media screen and (max-width: 991px) {
          width: 100%;
        }
        &:last-child {
          margin-bottom: 0;
        }
        span {
          display: block;
          margin-bottom: 5px;
          @media screen and (max-width: 991px) {
            margin-bottom: 5px;
          }
          &:last-child {
            font-family: roboto-bold;
            margin-bottom: 0px;
          }
        }
      }
    }
    .editbtn-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      @media screen and (max-width: 991px) {
        flex-direction: column;
      }
      .btn {
        margin-bottom: 20px;
        text-transform: none;
        min-width: 160px;
      }
    }
    .orderhistroy {
      .noorder {
        p {
          text-align: center;
          margin: 60px 0;
          font-size: 20px;
          a {
            color: $color-primary;
            font-size: 16px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      table {
        .address {
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            max-width: 150px;
            li {
              margin-right: 5px; 
              display: inline-block;
              &::after {
                content: ',';
              }
              &:last-child {
                &::after {
                  content: '.';
                }
              }
            }
          }
        }
        strong {
          display: none;
        }
        .btn-link {
          padding: 0;
          margin: 0;
          color: $color-primary;
          font-size: 14px;
          font-family: roboto-regular;
          min-width: auto;
          width: auto;
          &:hover {
            color: rgba($color-primary, .6);
          }
        }
        @media screen and (max-width: 991px) {
          thead {
            display: none;
          }
          tr {
            border-bottom: 1px solid rgba($color-gray, .3);
            td {
              display: flex;
              align-items: baseline;
              justify-content: flex-start;
              border: 0;
              strong, span {
                width: 50%;
                display: block;
              }
              button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}