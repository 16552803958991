.card-col {
  display: flex;
  @media screen and (max-width: 991px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.card-default {
  display: block;
  background-color: $color-secondary;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  border: 1px solid rgba($color-gray, 0.11);
  @media screen and (max-width: 991px) {
    margin-bottom: 10px;
  }
  &:hover {
    box-shadow: 0px 3px 6px rgba($color-dark, 0.2);
  }
  figure {
    margin-bottom: 0;
    background: rgba($color-gray, 0.05);
    padding: 0;
    .card-img {
      img {
        width: 100%;
        opacity: 0;
      }
    }
  }
  .card-info {
    padding: 15px;
    @media screen and (max-width: 575px) {
      padding: 12px 8px;
    }
    h3 {
      font-size: 18px;
      margin-bottom: 15px;
      font-family: worksan-semibold;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media screen and (max-width: 575px) {
        font-size: 16px;
      }
    }
    .card-info-bottom {
      position: relative;
      p {
        font-size: 14px;
        margin-bottom: 0;
        color: rgba($color-gray, 0.8);
      }
    }
  }
}

.card-default:hover {
  text-decoration: none;
}

.card-category {
  display: block;
  background-color: $color-secondary;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  @media screen and (max-width: 991px) {
    margin-bottom: 10px;
  }
  &:hover {
    .card-info {
      h3 {
        color: $color-primary;
      }
    }
    figure {
      .card-img {
        transform: scale(1.1);
      }
    }
    .hover {
      opacity: 1;
    }
  }
  figure {
    margin-bottom: 0;
    background: rgba($color-gray, 0.05);
    padding: 0;
    border: 1px solid rgba($color-gray, 0.11);
    overflow: hidden;
    position: relative;
    .card-img {
      transform: scale(1);
      transition: all 0.4s ease-in-out;
      img {
        width: 100%;
        opacity: 0;
      }
    }
    .hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: all 0.2s ease-in-out;
      img {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }
  .card-info {
    padding: 15px;
    h3 {
      font-size: 18px;
      margin-bottom: 0px;
      font-family: roboto-bold;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.3s;
    }
    .card-info-bottom {
      position: relative;
      p {
        font-size: 14px;
        margin-bottom: 0;
        color: rgba($color-gray, 0.8);
      }
    }
  }
}

.card-category:hover {
  text-decoration: none;
}
