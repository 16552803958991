label {
  font-family: roboto-medium;
  font-size: 16px;
}
input, .form-control {
  border-radius: 0;
  border: 1px solid rgba($color-gray, .2);
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid rgba($color-gray, .2);
  }
}
input, select {
  outline: none;
}

textarea::-webkit-input-placeholder, input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba($color-gray, .4) !important;
}
textarea::-moz-placeholder, input::-moz-placeholder { /* Firefox 19+ */
  color: rgba($color-gray, .4) !important;
}
textarea:-ms-input-placeholder, input:-ms-input-placeholder { /* IE 10+ */
  color: rgba($color-gray, .4) !important;
}
textarea:-moz-placeholder, input:-moz-placeholder { /* Firefox 18- */
  color: rgba($color-gray, .4) !important;
}

.isrequired {
  &:after {
    content: '*';
    margin-left: 5px;
    color: $color-danger;
  }
}

.error {
  color: $color-danger;
  margin-bottom: 0;
  font-size: 14px;
  margin-top: 5px;
}

.error-border {
  border: 1px solid $color-danger;
  // animation: shake 0.4s 1 linear;
  // -webkit-animation: shake 0.4s 1 linear;
  // -moz-animation: shake 0.4s 1 linear;
}
// @keyframes shake {
//   0% { -webkit-transform: translate(30px); }
//   20% { -webkit-transform: translate(-30px); }
//   40% { -webkit-transform: translate(15px); }
//   60% { -webkit-transform: translate(-15px); }
//   80% { -webkit-transform: translate(8px); }
//   100% { -webkit-transform: translate(0px); }
// }
// @-webkit-keyframes shake {
//   0% { -webkit-transform: translate(30px); }
//   20% { -webkit-transform: translate(-30px); }
//   40% { -webkit-transform: translate(15px); }
//   60% { -webkit-transform: translate(-15px); }
//   80% { -webkit-transform: translate(8px); }
//   100% { -webkit-transform: translate(0px); }
// }
// @-moz-keyframes shake {
//   0% { -moz-transform: translate(30px); }
//   20% { -moz-transform: translate(-30px); }
//   40% { -moz-transform: translate(15px); }
//   60% { -moz-transform: translate(-15px); }
//   80% { -moz-transform: translate(8px); }
//   100% { -moz-transform: translate(0px); }
// }
