.pagi-wrap {
  margin: 20px 0;
  .rc-pagination {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    li {
      cursor: pointer;
      &:focus {
        outline: none;
      }
      a, button {
        text-decoration: none;
        position: relative;
        display: block;
        padding: .5rem .75rem;
        margin: 0 3px;
        line-height: 1.25;
        background-color: transparent;
        border: 1px solid transparent;
        font-weight: 500;
        transition: all .2s;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: $color-primary;
          color: $color-secondary;
        }
      }
      &.rc-pagination-disabled {
        a, button {
          background-color: transparent;
        }
      }
      button {
        &:focus {
          outline: none;
        }
        &:before {
          content: '';
          width: 12px;
          height: 12px;
          display: inline-block;
        }
      }
      &.rc-pagination-item-active {
        a {
          background: $color-primary;
          color: $color-secondary;
        }
      }
      &.rc-pagination-prev {
        button {
          margin: 0;
          margin-right: 3px;
          border-radius: 50%;
          &:before {                        
            background: url('../../assets/img/chevron-left-solid-black.svg') no-repeat center;
          }
          &:hover {
            &:before {                        
              background: url('../../assets/img/chevron-left-solid.svg') no-repeat center;
            }
          }
        }
      }
      &.rc-pagination-prev.rc-pagination-disabled {
        button {
          &:hover {
            &:before {                        
              background: url('../../assets/img/chevron-left-solid-black.svg') no-repeat center;
            }
          }
          &:before {
            opacity: .4;
          }
        }
      }
      &.rc-pagination-next.rc-pagination-disabled {
        button {
          &:hover {
            &:before {                        
              background: url('../../assets/img/chevron-right-solid-black.svg') no-repeat center;
              opacity: .5;
            }
          }
          &:before {
            opacity: .4;
          }
        }
      }
      &.rc-pagination-next {
        button {
          border-radius: 50%;
          margin: 0;
          margin-left: 3px;
          &:before {
            background: url('../../assets/img/chevron-right-solid-black.svg') no-repeat center;
          }
          &:hover {
            &:before {                        
              background: url('../../assets/img/chevron-right-solid.svg') no-repeat center;
            }
          }
        }
      }
      &.rc-pagination-jump-prev, &.rc-pagination-jump-next {
        button {
          &:before {
            content: '...';
          }
        }
      }
    }
  }
}