#aboutus {
  .about-section {
    padding-top: 50px;
    padding-bottom: 50px;
    img {
      width: 100%;
      margin-bottom: 45px;
    }
    h2 {
      margin-bottom: 20px;
    }
  }
}