.no-product {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  .no-title {
    color: $color-danger;
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
    transform: translateX(-15px);
    &::after {
      content: '!';
      display: inline-block;
      width: 25px;
      height: 25px;
      border: 2px solid $color-danger;
      border-radius: 50%;
      font-size: 17px;
      position: absolute;
      top: 0;
      right: -30px;
    }
  }
  .no-desc {
    font-family: roboto-medium;
    margin-bottom: 0px;
    font-size: 26px;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
  .no-small {
    font-size: 18px;
  }
  .no-btn {
    margin-top: 15px;
  }
}