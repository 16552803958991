// constant
@import "./constant/colors";
@import "./constant/typo";

// component
@import "./styles/components/header";
@import "./styles/components/footer";
@import "./styles/components/card";
@import "./styles/components/form";
@import "./styles/components/button";
@import "./styles/components/title";
@import "./styles/components/pagination";
@import "./styles/components/breadcrumb";
@import "./styles/components/popup";
@import "./styles/components/tab";
@import "./styles/components/table";
@import "./styles/components/noproduct";

// layout
@import "./styles/layouts/home";
@import "./styles/layouts/product";
@import "./styles/layouts/account";
@import "./styles/layouts/profile";
@import "./styles/layouts/editprofile";
@import "./styles/layouts/productdetail";
@import "./styles/layouts/orderdetail";
@import "./styles/layouts/preorder";
@import "./styles/layouts/contact";
@import "./styles/layouts/about";
@import "./styles/layouts/terms";

::selection {
  background: $color-primary;
  color: $color-light;
}
pre {
  white-space: normal;
}
html {
  scroll-behavior: smooth;
}
body {
  transition: all 0.3s ease;
  color: $color-gray;
  font-family: roboto-regular;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-gray;
    font-family: worksan-bold;
    @media screen and (max-width: 991px) {
      font-size: 22px;
    }
  }
  p,
  a {
    color: $color-gray;
    font-family: roboto-regular;
  }
  a {
    transition: all 0.3s;
    &:hover {
      text-decoration: none;
      color: $color-primary;
    }
  }
  #open-bugger-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba($color-dark, 0.6);
    z-index: 999999999;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0s, transform 0s ease-in-out 0.3s;
    transform: translate3d(100%, 0, 0);
  }
  &.open-bugger {
    @media screen and (max-width: 991px) {
      transform: translateX(-250px);
      height: 100vh;
      min-height: 100vh;
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }

    .open-wrap {
      // position: fixed;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100vh;
      // background: rgba($color-dark, 0.6);
      // z-index: 999999999;
      opacity: 1 !important;
      transition: opacity 0.3s ease-in-out 0s !important;
      transform: translate3d(0, 0, 0) !important;
      &:after {
        content: "";
        display: inline-block;
        width: 36px;
        height: 36px;
        background: url("assets/img/close-bg.svg") no-repeat center/cover
          $color-secondary;
        position: absolute;
        right: 16px;
        top: 48px;
        border-radius: 50%;
        border: 2px solid $color-primary;
      }
    }
  }
  main {
    .slick-dots {
      display: flex;
      justify-content: center;
      padding-left: 0;
      width: 100%;
      li {
        list-style: none;
        margin: 0 10px;
        button {
          border: none;
          background: none;
          color: transparent;
          width: 14px;
          height: 14px;
          border-radius: 8px;
          box-sizing: border-box;
          overflow: hidden;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
  .notfound {
    padding-top: 0;
    padding-bottom: 30px;
    background: #ededed;
    img {
      width: 100%;
      max-width: 800px;
      filter: grayscale(1);
    }
    .cusbtn {
      background: #73be20;
      border-radius: 3px;
      box-shadow: 3px 5px 5px rgba($color-dark, 0.5);
      @media screen and (max-width: 767px) {
        font-size: 12px;
        min-width: 100px;
        padding: 6px 10px;
      }
      &:hover {
        background: #5e9b1b;
      }
    }
  }
  article {
    min-height: calc(100vh - 490px);
  }
}
.popup-body {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 800ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 800ms;
}
