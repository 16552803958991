.footer {
  background: $color-dark;
  font-family: roboto-light;
  hr {
    margin: 0;
  }
  p {
    margin-bottom: 0;
    
  }
  h3 {
    font-size: 18px;
    color: #FFF;
    margin-bottom: 25px;
  }
  .section-contact {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    @media screen and (max-width: 991px) {
      margin-bottom: 0;
    }
    .contact-col {
      @media screen and (max-width: 991px) {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .contact {
        margin-bottom: 15px;
        @media screen and (max-width: 991px) {
          &:last-child {
            margin-bottom: 0;
          }
        }
        p {
          font-size: 15px;
          display: flex;
          justify-content: flex-start;
          color: rgba($color-secondary, .7);
          .label {
            padding-right: 20px;
            position: relative;
            color: $color-secondary;
            min-width: 80px;
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            &:after {
              content: ':';
              position: absolute;
              right: 10px;
            }
          }
          .contact-list {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            flex-wrap: wrap;
            a {
              color: rgba($color-secondary, .7);
              &::after {
                content: ',';
                margin-right: 5px;
              }
              &:last-child {
                &::after {
                  display: none;
                }
              }
              &:hover {
                color: $color-primary;
              }
            }
            &.email {
              flex-direction: column;
            }
          }
        }
      }
      .payment {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        li {
          margin-right: 15px;
          img {
            width: 50px;
          }
        }
      }
    }
  }
  .links {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 14px;
    li {
      margin-bottom: 15px;
      a {
        color: rgba($color-secondary, .7);
        &:hover {
          color: $color-primary;
        }
      }
    }
  }
  .copyright {
    padding: 15px 0;
    p {
      font-size: 12px;
      color: rgba($color-secondary, .7);
      a {
        color: $color-primary;
        &:hover {
          color: rgba($color-primary, .7);
        }
      }
    }
  }
}