#home {
  .banner-item {
    margin-bottom: 30px;
    position: relative;
    min-height: 250px;
    @media screen and (max-width: 767px) {
      min-height: 100px;
    }
    &:focus {
      outline: none;
    }
    img {
      width: 100%;
    }
  }
  .caption {
    position: absolute;
    top: 50%;
    background: rgba($color-dark, 0.7);
    color: $color-secondary;
    margin-bottom: 0;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 32px;
    padding: 50px 30px;
    width: 100%;
    max-width: 500px;
    text-align: center;
    font-family: worksan-bold;
    @media screen and (max-width: 1199px) {
      font-size: 22px;
    }
    @media screen and (max-width: 991px) {
      font-size: 14px;
      max-width: 240px;
      padding: 10px;
    }
  }
  .slick-slide {
    &:focus {
      outline: none;
    }
  }
  .slick-arrow {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    width: 25px;
    height: 25px;
    background: $color-primary;
    z-index: 9;
    transition: all .2s;
    @media screen and (max-width: 767px) {
      width: 20px;
      height: 20px;
    }
    &::before {
      content: '';
      width: 16px;
      height: 16px;
      display: inline-block;
      @media screen and (max-width: 767px) {
        width: 12px;
        height: 12px;
      }
    }
    &:hover {
      background: rgba($color-primary, .8)
    }
    @media screen and (max-width: 575px) {
      top: 40%;
    }
  }
  .slick-prev {
    left: 30px;
    &::before {
      background: url('../../assets/img/back.svg') no-repeat center/cover;
    }
  }
  .slick-next {
    right: 30px;
    &::before {
      background: url('../../assets/img/next.svg') no-repeat center/cover;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 10px;
    margin-bottom: 0;
    li {
      margin: 0 5px;
      line-height: 0;
    }
  }

  // category
  .category-section {
    margin-bottom: 100px;
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }
    .category-card {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      background: rgba($color-gray, .05);
      padding: 60px 30px;
      margin-bottom: 15px;
      border: 1px solid rgba($color-gray, .11);
      .label {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
        @media screen and (max-width: 1199px) {
          left: 0;
          padding: 30px;
          // background: rgba(255,255,255,.7);
          width: 100%;
          height: 100%;
        }
        p {
          font-family: roboto-regular;
          font-size: 14px;
          margin-bottom: 20px;
          text-transform: uppercase;
        }
        h2 {
          font-family: worksan-medium;
          font-size: 30px;
          color: $color-primary;
          margin-bottom: 20px;
          @media screen and (max-width: 991px) {
            font-size: 20px;
          }
        }
        .btn-link {
          padding: 0;
          min-width: auto;
          font-style: italic;
        }
      }
      .category-img {
        width: 250px;
        @media screen and (max-width: 575px) {
          width: 140px;
        }
        img {
          opacity: 0;
          width: 100%;
        }
      }
    }
  }
  .product-section {
    margin-bottom: 50px;
    .desc {
      text-align: center;
      margin-bottom: 50px;
    }
    .view-more {
      text-align: center;
      .btn-transparent {
        min-width: 200px;
        font-family: roboto-bold;
        font-size: 14px;
        @media screen and (max-width: 991px) {
          min-width: 160px;
        }
      }
    }
  }
  .deli-service {
    background: transparent linear-gradient(82deg, #215F00 0%, #000000 100%) 0% 0% no-repeat padding-box;
    h2 {
      font-family: worksan-bold;
      font-size: 32px;
      margin-bottom: 0;
      padding: 55px 0;
      text-align: center;
      color: $color-secondary;
      line-height: 1.5;
      @media screen and (max-width: 991px) {
        font-size: 20px;
      }
    }
  }
  .upper-footer {
    background: $color-secondary;
    padding-top: 50px;
    padding-bottom: 50px;
    @media screen and (max-width: 991px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .info-content {
      @media screen and (max-width: 991px) {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
      }
      .img-wrap {
        background: transparent linear-gradient(180deg, #3BD100 0%, #1E6900 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        @media screen and (max-width: 991px) {
          margin: 0 auto 10px;
        }
        img {
          width: 50px;
        }
      }
      .content {
        width: calc(100% - 70px);
        @media screen and (max-width: 991px) {
          width: 100%;
          text-align: center;
        }
        h3 {
          font-size: 20px;
          color: $color-gray;
          margin-bottom: 12px;
        }
        p {
          color: rgba($color-gray, .7);
        }
      }
    }
  }
}

// .card-row {
//   margin-left: -10px;
//   margin-right: -10px;
//   @media screen and (max-width: 991px) {
//     margin-left: 0;
//     margin-right: 0;
//   }
// }