@font-face {
    font-family: roboto-light;
    src: url('../assets/fonts/Roboto/Roboto-Light.ttf');
}
@font-face {
    font-family: roboto-medium;
    src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
}
@font-face {
    font-family: roboto-regular;
    src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
}
@font-face {
    font-family: roboto-bold;
    src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
}
@font-face {
    font-family: worksan-light;
    src: url('../assets/fonts/Work_Sans/WorkSans-Light.ttf')
}
@font-face {
    font-family: worksan-medium;
    src: url('../assets/fonts/Work_Sans/WorkSans-Medium.ttf')
}
@font-face {
    font-family: worksan-regular;
    src: url('../assets/fonts/Work_Sans/WorkSans-Regular.ttf')
}
@font-face {
    font-family: worksan-semibold;
    src: url('../assets/fonts/Work_Sans/WorkSans-SemiBold.ttf')
}
@font-face {
    font-family: worksan-bold;
    src: url('../assets/fonts/Work_Sans/WorkSans-Bold.ttf')
}